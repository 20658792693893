.line {
  background-color: #ffffff;
  display: grid;
  height: 50px;
  align-items: center;
  grid-auto-flow: column;
  border-top: solid 1px #181818;
  border-bottom: solid 1px #181818;
  grid-auto-columns: min-content;

  &__item {
    position: relative;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    color: #181818;
    padding-right: 50px;

    &:before {
      position: absolute;
      content: '';
      width: 4px;
      height: 4px;
      background-color: #FBE694;
      right: 24px;
      top: 0;
      bottom: 0;
      margin: auto;
      pointer-events: none;
      border-radius: 100%;
    }
  }
}
