.card {
  width: 200px;
  user-select: none;

  &__image {
    position: relative;
    width: 100%;
    height: 275px;
    background-color: #ffffff;
    margin-bottom: 10px;

    img {
      height: 275px;
      width: 200px;
      object-fit: cover;
      object-position: center;
    }
  }

  &__button {
    font-family: 'Helvetica Neue', 'HelveticaNeueCyr', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
    position: absolute;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    background-color: rgb(24, 24, 24, .8);
    color: #FBE694;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 300;
    opacity: 0;
    transition: .2s;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    height: 32px;
  }
}
