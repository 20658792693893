.header {
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  color: #181818;

  &__container {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
  }

  &__content {
    padding: 130px 0 155px;
    display: grid;
    grid-template-columns: max-content 1fr;

    @media (max-width: 860px) {
      row-gap: 30px;
      grid-template-columns: 1fr;
    }
  }

  &__text {
    max-width: 700px;

    @media (max-width: 860px) {
      justify-self: center;
    }
  }

  &__title {
    font-weight: 300;
    font-size: 32px;
    padding-bottom: 5px;

    span {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &__subTitle {
    font-weight: 300;
    font-size: 16px;
    padding-top: 10px;
  }

  &__button {
    justify-self: end;
    align-self: center;

    @media (max-width: 860px) {
      grid-template-columns: 1fr;
      justify-self: center;
    }
  }
}
