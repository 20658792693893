.schedule {
  background-color: #ffffff;
  padding-top: 65px;
  padding-bottom: 65px;

  &__container {
    display: grid;
  }

  &__tables {
    padding-top: 55px;
    position: relative;
    display: grid;
    align-content: center;
  }
}
