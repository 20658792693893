.menu {
  display: grid;
  row-gap: 10px;
  grid-column: 1;
  align-items: start;
  align-content: start;
  justify-content: start;
  padding-top: 55px;
  padding-bottom: 65px;

  &__button {
    font-family: 'Helvetica Neue', 'HelveticaNeueCyr', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
    text-align: left;
    border: none;
    outline: none;
    background-color: initial;
    font-weight: 700;
    padding: 0;
    font-size: 16px;
    text-transform: uppercase;
    max-width: 200px;
    color: #181818;

    &:hover {
      cursor: pointer;
    }

    &_active {
      color: #FBE694;
    }
  }
}
