.card {
  position: relative;
  display: grid;
  grid-template-columns: min-content min-content;
  align-items: center;
  column-gap: 10px;
  height: 128px;

  &__number {
    z-index: 1;
    font-weight: 700;
    font-size: 64px;
    line-height: 74px;
    text-transform: uppercase;
    color: #181818;

    @media (max-width: 468px) {
      font-size: 48px;
    }
  }

  &__text {
    z-index: 1;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    color: #181818;

    @media (max-width: 468px) {
      font-size: 12px;
    }
  }

  &__background {
    position: absolute;
    font-weight: 700;
    font-size: 128px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: -1px 0 rgb(24, 24, 24, .1), 0 1px rgb(24, 24, 24, .1), 1px 0 rgb(24, 24, 24, .1), 0 -1px rgb(24, 24, 24, .1);
    z-index: 0;
    top: 0;
    right: -10px;

    @media (max-width: 468px) {
      font-size: 72px;
      right: -5px;
    }
  }

  &_dark {
    .card {
      &__number {
        color: #FFFFFF;
      }

      &__text {
        color: #FFFFFF;
      }

      &__background {
        color: #181818;
        text-shadow: -1px 0 rgb(255, 255, 255, .1),
                      0 1px rgb(255, 255, 255, .1),
                      1px 0 rgb(255, 255, 255, .1),
                      0 -1px rgb(255, 255, 255, .1);
      }
    }
  }

  @media (max-width: 468px) {
    height: 72px;
  }
}
