.card {
  width: 400px;
  user-select: none;

  &__image {
    background-color: #181818;
    margin-bottom: 10px;
    font-size: 0;

    img {
      height: 275px;
      width: 400px;
      object-fit: cover;
      object-position: center;
    }
  }

  &__category {
    font-size: 12px;
    font-weight: 700;
    padding-bottom: 15px;
    text-transform: uppercase;
    opacity: .3;
  }

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }
}
