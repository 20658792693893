.sports {
  padding-bottom: 65px;
  color: #ffffff;
  overflow-x: visible;

  &__container {
    max-width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr;
    column-gap: 30px;

    @media (max-width: 550px) {
      grid-template-columns: 1fr 75px;
      column-gap: 10px;
    }
  }

  &__nextButton {
    display: none;
    width: 40px;
    height: 28px;
    margin: 0;
    padding: 0;

    cursor: pointer;

    border: none;
    background-color: initial;
    background-image: url("data:image/svg+xml,%3Csvg width='512px' height='512px' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon fill='%23FBE694' points='359.873 121.377 337.246 144.004 433.243 240.001 16 240.001 16 240.002 16 272.001 16 272.002 433.24 272.002 337.246 367.996 359.873 390.623 494.498 256 359.873 121.377'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (max-width: 1600px) {
      display: block;
    }
  }
}
