.news {
  background-color: #181818;
  padding-top: 65px;
  padding-bottom: 65px;
  color: #ffffff;

  &__carousel {
    padding-bottom: 65px;
  }
}
