.schedule {
  background-color: #ffffff;
  padding-top: 65px;
  padding-bottom: 65px;

  &__container {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 38px 1fr;

    @media (max-width: 960px) {
      grid-template-columns: 1fr;
      grid-template-rows: 38px min-content 1fr;
    }
  }

  &__tables {
    position: relative;
    display: grid;
    align-content: center;
    grid-column: 2;
    grid-row: 1/3;

    @media (max-width: 960px) {
      grid-column: 1;
      grid-row: 3;
    }
  }
}
