.carousel {
  max-width: calc(100vw - 30px);

  &__button {
    position: relative;
    font-family: 'Helvetica Neue', 'HelveticaNeueCyr', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
    text-align: left;
    border: none;
    outline: none;
    background-color: initial;
    font-weight: 700;
    padding: 15px 0 25px;
    margin-right: 50px;
    font-size: 16px;
    text-transform: uppercase;
    color: #181818;
    display: table;
    user-select: none;

    &:hover {
      cursor: pointer;
    }

    &:before {
      position: absolute;
      content: '';
      width: 4px;
      height: 4px;
      background-color: #FBE694;
      right: -25px;
      top: 0;
      bottom: 11px;
      margin: auto;
      pointer-events: none;
      border-radius: 100%;
    }

    &_active {
      color: #FBE694;
    }
  }
}
