.logotype {
  font-weight: 700;
  font-size: 24px;
  color: #181818;
  text-transform: uppercase;
  text-decoration: none;

  &_white {
    color: #ffffff;
  }

  span {
    color: #FBE694;
  }
}
