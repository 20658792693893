.subTitle {
  max-width: 410px;
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  color: #181818;

  &_white {
    color: #ffffff;
  }
}
