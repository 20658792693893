.container {
    max-width: 1024px;
    padding: 0 15px;
    margin: auto;
}

.container-fluid {
    width: calc(100% - (100% - 1024px) / 2);
    margin-left: calc((100% - 1054px) / 2);
    padding-left: 15px;

    @media (max-width: 1024px) {
        padding-left: 15px;
        width: 100%;
        margin: 0;
        box-sizing: border-box;
    }
}
