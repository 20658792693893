.copyright {
  text-transform: uppercase;
  font-size: 12px;
  opacity: .5;
  cursor: default;

  &__link {
    color: #FBE694;
    text-decoration: none;
  }

  &:hover {
    opacity: 1;
  }
}
