.addresses {
  background-color: #ffffff;
  padding-top: 65px;
  padding-bottom: 65px;

  &__container {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 38px 1fr;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &__list {
    display: grid;
    row-gap: 30px;
    padding-top: 55px;
    grid-column: 1;
    align-content: start;

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-column: 1;
      grid-row: 3;
    }

    @media (max-width: 425px) {
      grid-template-columns: max-content;
      justify-content: center;
    }
  }

  &__map {
    grid-column: 2;
    grid-row: 1/3;

    iframe {
      min-height: 300px;
      height: 100%;
    }

    @media (max-width: 768px) {
      grid-column: 1;
      grid-row: 2;
      padding-top: 55px;
    }
  }
}
