.address {
  &__title {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: #181818;
    margin-bottom: 10px;

    @media (max-width: 468px) {
      font-size: 12px;
    }
  }

  &__link {
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    color: #181818;
    text-decoration: none;
  }
}
