.trainers {
  padding: 65px 0;
  color: #FFFFFF;

  &__container {
    display: grid;
    padding-top: 55px!important;
  }
}

.trainersCard {
  display: grid;
  grid-template-columns: min-content 1fr;

  img {
    width: 200px;
  }

  &__info {
    padding: 25px;
    box-sizing: border-box;
    width: 100%;
  }

  &__name {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__list {
    padding: 10px 0;
    list-style: none;

    li {
      font-size: 16px;
      line-height: 18px;
      position: relative;
      padding: 5px 0 5px 10px;
      max-width: 650px;

      &:before {
        position: absolute;
        content: "";
        width: 4px;
        height: 4px;
        background-color: #FBE694;
        left: 0;
        top: 12px;
        pointer-events: none;
        border-radius: 100%;
      }
    }
  }

  &__text {
    max-width: 350px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;

    img {
      margin: auto;
    }

    &__name {
      text-align: center;
    }

    &__list {
      padding: 10px 0;
      list-style: none;

      li {
        font-size: 14px;
      }
    }
  }
}
