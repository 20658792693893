.title {
  width: 410px;
  max-width: 100%;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #181818;
  padding-bottom: 10px;
  line-height: 28px;

  &_white {
    color: #ffffff;
  }

  @media (max-width: 468px) {
    font-size: 20px;
    width: 100%;
  }
}
