.navigation {
  font-weight: 700;
  font-size: 16px;
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  padding: 42px 0;

  &__link {
    color: #181818;
    text-transform: uppercase;
    text-decoration: none;

    &_active {
      color: #FBE694;
    }

    &:hover {
      opacity: .6;
    }
  }

  &_white {
    .navigation {
      &__link {
        color: #FFFFFF;

        &:hover {
          opacity: 1;
          color: #FBE694;
        }
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
}
