.form {
  display: grid;
  width: 100%;
  row-gap: 10px;
  padding-bottom: 65px;

  &__field {
    font-family: 'Helvetica Neue', 'HelveticaNeueCyr', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
    width: 100%;
    padding: 0;
    margin: 0;
    height: 32px;
    font-size: 16px;
    border: none;
    outline: none;
    border-bottom: solid 1px #181818;
    border-radius: 0;

    &::placeholder {
      color: #181818;
      font-weight: 300;
    }
  }

  &__button {
    font-family: 'Helvetica Neue', 'HelveticaNeueCyr', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
    border: 0;
    outline: 0;
    background-color: #181818;
    color: #FFFFFF;
    padding: 10px 0;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 25px;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      opacity: .5;
      pointer-events: none;
    }

    @media (max-width: 768px) {
      max-width: 50%;
      min-width: 200px;
      margin: 25px auto 0;
    }
  }
}
