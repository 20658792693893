.contacts {
  display: grid;
  grid-auto-flow: column;
  column-gap: 50px;

  &__link {
    position: relative;
    font-weight: 300;
    font-size: 16px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-decoration: none;

    &:hover {
      color: #FBE694;
    }

    &:before {
      position: absolute;
      content: '';
      width: 4px;
      height: 4px;
      background-color: #FBE694;
      right: -28px;
      top: 0;
      bottom: 0;
      margin: auto;
      pointer-events: none;
      border-radius: 100%;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &:last-child {
      &:before {
        display: none;
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-rows: 1fr 1fr;
    gap: 20px 35px;
    justify-items: end;
  }
}
