.footer {
  background-color: #181818;
  padding-top: 65px;
  padding-bottom: 40px;
  color: #ffffff;

  &__container {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;

    @media (max-width: 768px) {
      align-items: start;
      grid-template-columns: 1fr;
    }
  }

  &__navigation {
    justify-self: end;
    grid-column: 2;
  }

  &__contacts {
    justify-self: end;
    grid-column: 2;
    padding: 25px 0;
  }
}
