.circleButton {
  display: table;
  position: relative;

  &__link {
    position: relative;
    display: block;
    text-transform: uppercase;
    color: #FBE694;
    text-decoration: none;
    font-weight: 700;
    padding: 30px 0;
    z-index: 1;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
    font-size: 18px;
  }

  &__circle {
    position: absolute;
    height: 100%;
    right: -15px;
    top: 0;
    opacity: .2;
    animation: rotate 10s linear infinite;
    transition: .3s;
    pointer-events: none;
  }

  &:hover {
    .circleButton {
      &__circle {
        opacity: .7;
      }
    }
  }

  &_white {
    .circleButton {
      &__link {
        color: #181818;
        text-shadow: 0px 0px 5px rgba(255, 255, 255, 1);
      }

      &__circle {
        opacity: 1;
      }
    }
  }
}


@keyframes rotate {
  100% {
    transform:rotate(360deg);
  }
}
