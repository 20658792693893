.benefits {
  background-color: #ffffff;
  padding-top: 65px;
  padding-bottom: 65px;

  &__container {
    display: grid;
    grid-template-rows: min-content min-content;
  }

  &__cards {
    padding-bottom: 37px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    gap: 30px;
    justify-content: space-between;

    @media (max-width: 768px) {
      grid-template-rows: 1fr 1fr;
      justify-content: space-around;
      grid-auto-columns: 1fr;
      justify-items: center;
    }
  }

  &_dark {
    background-color: #181818;
  }
}
