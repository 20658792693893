.application {
  background-color: #ffffff;
  padding-top: 65px;

  &__form {
    padding: 30px 0 0;
    max-width: 410px;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
}
