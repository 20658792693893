.container {
  position: relative;
  display: grid;
  align-content: center;
  row-gap: 35px;
  overflow-x: scroll;
  padding-bottom: 25px;

  &::-webkit-scrollbar {
    height: 2px;
    background-color: initial;
    padding: 5px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #FBE694;
  }
}

.table {
  width: 100%;
  min-width: 560px;
  color: #181818;

  &__thead {
    border-bottom: solid 1px #181818;
  }

  &__th {
    text-align: left;
    font-weight: 300;
    font-size: 16px;
    padding-bottom: 10px;

    @media (max-width: 768px) {
      padding: 0 10px 10px;
    }

    &_gym {
      width: 50%;
      font-weight: 700;
      text-transform: uppercase;

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  &__td {
    font-weight: 300;
    font-size: 16px;
    padding: 10px 0;

    span {
      background-color: #FBE694;
      border-radius: 3px;
      padding: 2px 4px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 9px;
    }

    @media (max-width: 768px) {
      padding: 15px 10px 0px;
    }
  }
}
