.navigation {
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;

  &__link {
    position: relative;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-decoration: none;

    &:hover {
      color: #FBE694;
    }
  }

  @media (max-width: 768px) {
    grid-template-rows: 1fr 1fr;
    gap: 20px 35px;
    justify-items: end;
  }
}
